// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-user-edit-js": () => import("./../../../src/pages/admin-user-edit.js" /* webpackChunkName: "component---src-pages-admin-user-edit-js" */),
  "component---src-pages-admin-user-js": () => import("./../../../src/pages/admin-user.js" /* webpackChunkName: "component---src-pages-admin-user-js" */),
  "component---src-pages-admin-user-new-js": () => import("./../../../src/pages/admin-user-new.js" /* webpackChunkName: "component---src-pages-admin-user-new-js" */),
  "component---src-pages-admin-users-js": () => import("./../../../src/pages/admin-users.js" /* webpackChunkName: "component---src-pages-admin-users-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-signup-edit-js": () => import("./../../../src/pages/signup-edit.js" /* webpackChunkName: "component---src-pages-signup-edit-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-signup-new-js": () => import("./../../../src/pages/signup-new.js" /* webpackChunkName: "component---src-pages-signup-new-js" */),
  "component---src-pages-signups-js": () => import("./../../../src/pages/signups.js" /* webpackChunkName: "component---src-pages-signups-js" */)
}

